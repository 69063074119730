<template>
	<div class="timeline">
		<ul class="timeline-legend">
			<TimelineLegendItem
				v-for="item in diets"
				:key="item.slug"
				:item="item"
			/>
		</ul>

		<ul class="timeline-switch">
			<TimelineSwitchItem
				v-for="item in erathems"
				:key="item.slug"
				:item="item"
			/>
		</ul>

		<div class="timeline-wrap">
			<div class="timeline-head">
				<div
					v-for="item in periods"
					:key="item.slug"
					:data-start="Math.trunc(item.start)"
					:data-end="Math.trunc(item.end)"
					:class="'is-' + item.slug"
					:style="{width: getPeriodWidth(item)}"
					class="timeline-period"
				>
					<button
						:title="(period.name === item.name) ? 'Alle anzeigen' : item.name + ' auswählen'"
						@click="changeFilter('period', item)"
						@mouseenter="onHover(item.slug)"
						@mouseleave="onLeave"
					>
						<span>{{ item.name }}</span>
					</button>
				</div>
			</div>

			<div
				class="timeline-body"
				@mousemove="onHover"
				@mouseleave="onLeave"
			>
				<div class="timeline-bg">
					<div
						v-for="item in periods"
						:key="item.slug"
						:class="['is-' + item.slug, {'is-active': active.name === item.slug}]"
						:style="{width: getPeriodWidth(item)}"
					/>
				</div>
				<TimelineItem
					v-for="item in visibleItems"
					:key="item.name"
					:item="item"
					:items="items"
					:start="periods[0].start"
					:end="periods[periods.length - 1].end"
				/>
			</div>

			<template v-if="items.length > limit">
				<BasePaginationProgress
					:max="items.length"
					:value="limit"
				/>

				<button
					class="button button-primary more"
					@click="more"
				>
					<span>Mehr anzeigen</span>
				</button>
			</template>
		</div>
	</div>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import {FilterMixin, ModalMixin, MoreMixin} from '@/mixins';
import {getStartDimension, getEndDimension} from '@/utils/dimension';
import {has} from '@schascha/brabbelback';

export default {
	components: {
		TimelineItem: () => import(/* webpackChunkName: "TimelineItem" */ '@/components/timeline/TimelineItem'),
		TimelineLegendItem: () => import(/* webpackChunkName: "TimelineLegendItem" */ '@/components/timeline/TimelineLegendItem'),
		TimelineSwitchItem: () => import(/* webpackChunkName: "TimelineSwitchItem" */ '@/components/timeline/TimelineSwitchItem')
	},
	mixins: [FilterMixin, ModalMixin, MoreMixin],
	data() {
		return {
			limit: 24,
			active: {}
		};
	},
	computed: {
		...mapGetters(['getAnimalsByErathem']),
		...mapState(['diet', 'diets', 'erathem', 'erathems', 'period', 'taxon']),
		items() {
			return this.getAnimalsByErathem();
		},
		periods() {
			const {erathem, period} = this;

			return erathem && this.$store.state.periods.filter(({name}) =>
				has(erathem.periods, name) && !period.name || name === period.name);
		}
	},
	methods: {
		onHover(e) {
			if (typeof e === 'string') {
				this.active = {
					name: e
				};
			} else if (!this.active.name || e.clientX < this.active.left || e.clientX > this.active.right) {
				var items = this.$el.querySelectorAll('.timeline-bg > div');

				[...items].find(el => {
					var pos = el.getBoundingClientRect();

					if (pos.left <= e.clientX && pos.right >= e.clientX) {
						this.active = {
							name: el.classList[0].replace('is-', ''),
							left: pos.left,
							right: pos.right
						};

						return;
					}
				});
			}
		},
		onLeave() {
			this.active = {};
		},
		getPeriodWidth(x) {
			if (this.periods.length > 1) {
				const
					start = this.periods[0].start,
					end = this.periods[this.periods.length - 1].end
				;

				return (100 - getStartDimension(x.start, start, end) - getEndDimension(x.end, start, end)) + '%';
			} else {
				return '100%';
			}
		}
	}
};
</script>

<style lang="scss">
	.timeline {
		margin: 0 auto;
		max-width: $max-width;
		font-size: 0.875rem;
		text-align: center;

		&-legend,
		&-switch {
			position: relative;
			margin: 1rem -0.75rem;
			padding: 0;
			list-style: none;

			> li {
				display: inline-block;
				padding: 0.5rem 0.75rem;
				font-weight: bold;

				&:hover,
				&.is-active {
					button::before {
						transform: scale(1);
					}

					button {
						background-color: $color-light;
					}
				}
			}

			button {
				@include button();
				color: inherit;

				&::before {
					transition: transform $transition-duration ease-out;
					content: '';
					display: inline-block;
					transform: scale(0.5);
					margin-right: 0.5rem;
					width: 12px;
					height: 12px;
					background-color: currentColor;
					border-radius: 50%;
				}
			}

			span {
				color: $color-text;
			}

			.count {
				margin-left: 0.5rem;
				font-weight: normal;
			}
		}

		&-switch {
			margin-bottom: 2rem;

			button::before {
				content: none;
			}
		}

		&-head {
			transition: top 0.3s ease-in-out;
			display: flex;
			position: sticky;
			top: 29px;
			z-index: 1;
			padding-top: 2rem;
			background-color: $color-white;
			border-bottom: 1px solid rgba($color-black, 0.1);
		}

		&-period {
			transition: background-color $transition-duration ease-out;
			position: relative;
			border-left: 1px solid rgba($color-black, 0.1);
			color: $color-white;
			font-weight: bold;
			text-align: center;

			&::before,
			&::after {
				position: absolute;
				bottom: 100%;
				padding-bottom: 0.5rem;
				color: $color-text;
			}

			&::before {
				content: attr(data-start);
				transform: translateX(-50%);
				left: 0;
				color: $color-text;
			}

			&:only-child {
				width: 100%;
			}

			&:not(:hover) {
				background-color: inherit;
				color: inherit;
			}

			&:last-child {
				border-right: 1px solid rgba($color-black, 0.1);

				&::after {
					content: attr(data-end);
					transform: translateX(50%);
					right: 0;
				}
			}

			button {
				cursor: pointer;
				display: block;
				padding: 0.5rem 1rem;
				width: 100%;
				background-color: transparent;
				border: 0;
				outline: 0;
				color: inherit;
				font: inherit;
				text-decoration: none;
			}

			span {
				display: block;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		&-bg {
			display: flex;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;

			> div {
				transition: background-color 0.3s ease-out;
				pointer-events: auto;
				opacity: 0.1;
				height: 100%;

				&:not(.is-active) {
					background-color: inherit;
				}

				&:only-child {
					width: 100%;
				}
			}
		}

		&-body {
			position: relative;
			padding: 1rem 0;
		}

		&-item {
			position: relative;
			margin: 1rem 0;
			border-radius: 1rem;
			line-height: 1;
			text-align: center;

			@include mq($max: $max-width) {
				margin-top: 3rem;

				&::before {
					content: '';
					position: absolute;
					top: 50%;
					right: 0;
					left: 0;
					border-top: 1px solid $color-border;
				}
			}
		}

		&-icon {
			transform: translate(0, -50%);
			position: absolute;
			top: 50%;
			right: 100%;
			margin: 0;
			width: 40px;
			height: 40px;

			@include mq($max-width) {
				right: auto;
				left: 100%;
			}

			svg {
				transition: transform $transition-duration ease-out;
				width: 100%;
				height: 100%;
				fill: $color-brand;
			}
		}

		&-bar {
			animation: timelineBar 2s ease-out;
			cursor: pointer;
			height: 1rem;
			background-color: #dfdfdf;
			border-radius: 1rem;

			@include mq($max-width) {
				position: relative;
			}

			&:hover {
				svg {
					transform: scale(1.2);
				}
			}
		}

		&-info {
			transform: translate(-1rem, 0);
			position: absolute;
			top: -1.25rem;
			left: 4rem;
			padding: 0 0.5rem;
			font-size: 0.75rem;
			white-space: nowrap;

			@include mq($max-width) {
				transform: translate(0, -50%);
				top: 50%;
				left: 100%;
			}

			&::after {
				content: '';
				display: inline-block;
				margin-left: 0.5rem;
				width: 6px;
				height: 6px;
				background-color: currentColor;
				border-radius: 50%;
				vertical-align: middle;
			}
		}

		&-name {
			color: $color-text;
		}

		.align-right {
			.timeline-icon,
			.timeline-info {
				@include mq($max-width) {
					right: 100%;
					left: auto;
				}
			}
		}

		.align-center {
			&.timeline-item {
				margin-top: 3rem;
			}

			.timeline-bar {
				position: static;
			}

			.timeline-info {
				@include mq($max-width) {
					transform: translate(-1rem, 0);
					top: -1.25rem;
					left: 50%;
				}
			}

			.timeline-icon {
				@include mq($max-width) {
					right: 100%;
					left: auto;
				}
			}
		}

		.is-left-open {
			&.timeline-item,
			.timeline-bar {
				border-left: 0;
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}
		}

		.is-right-open {
			&.timeline-item,
			.timeline-bar {
				border-right: 0;
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
		}

		.more {
			margin: 1rem 0 2rem;
		}
	}

	@keyframes timelineBar {
		from {
			width: 0;
		}
	}
</style>
